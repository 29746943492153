.login-container{
    background-image: url("../../images/pixel_dao_bg.png");
    width: 100%;
    height: 110vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.top-log-in{
    height: 50%;
    background-image: url("../../images/top_bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
}

.big-logo{
    background-repeat: no-repeat;
    width: 360px;
    height: 260px;
    margin: 0 auto;
    padding: 95px 0 40px 0;
}

.big-logo img{
    width: 100%;
    height: 100%;
}

.btn-login{
    width: 211px;
    height: 72px;
    margin: 0 auto;
    position: relative;
}

.btn-login img{
    width: 100%;
    height: 100%;
}

.btn-login span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    color: #FFFFFF;
    font-size: 28px;
}

@media screen and (max-width: 1919px) {

}

@media screen and (max-width: 1799px) {
    .btn-login{
        width: 170px;
        height: 55px;
    }
    .big-logo{
        width: 320px;
        height: 220px;
    }
    .big-logo{
        padding: 70px 0 40px 0;
    }
}

@media screen and (max-width: 1599px) {
    .big-logo{
        width: 300px;
        height: 200px;
    }
    .big-logo{
        padding: 60px 0 40px 0;
    }
}

@media screen and (max-width: 1399px) {
    .big-logo {
        width: 270px;
        height: 170px;
    }
    .big-logo{
        padding: 50px 0 40px 0;
    }
}

@media screen and (max-width: 1199px) {
    .big-logo{
        width: 250px;
        height: 150px;
    }
    .big-logo{
        padding: 45px 0 40px 0;
    }
}

@media screen and (max-width: 999px) {

}
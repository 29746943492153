.avatar{
  background-image: url("../../images/avatar_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 215px;
  height: 245px;
  z-index: 9999;

  .avatar-logo{
    background-image: url("../../images/avatar.png");
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
    width: 196px;
    height: 196px;
    margin: 0 auto;
    position: relative;

    img {
      width: 90%;
      height: 89%;
      padding: 9px;
      border-radius: 50%;

      @media screen and (max-width: 1399px){
        width: 88%;
        height: 87%;
      }
    }

    @media screen and (max-width: 1699px){
      width: 167px;
      height: 167px;
    }

    @media screen and (max-width: 1399px){
      width: 140px;
      height: 140px;
    }
  }

  .upgrade-avatar{
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;

    @media screen and (max-width: 1699px){
      width: 30px;
      height: 30px;
    }

    @media screen and (max-width: 1399px){
      width: 25px;
      height: 25px;
    }
  }

  .avatar-info{
    font-size: 18px;
    color: white;
    text-align: center;
    margin-top: 12px;

    @media screen and (max-width: 1399px){
      font-size: 14px;
    }
  }

  .tailt-banner{
    margin-left: 20px;
    margin-top: 10px;

    @media screen and (max-width: 1699px){
      margin-top: 15px;
      width: 38px;
    }

    @media screen and (max-width: 1399px){
      margin-top: 10px;
      width: 32px;
    }
  }

  @media screen and (max-width: 1699px){
    width: 200px;
    height: 220px;
  }

  @media screen and (max-width: 1399px){
    width: 170px;
    height: 180px;
  }
}
iframe{
  width: 99.8%;
  height: 92%;
  position: absolute;
  bottom: 0;
  left: 0;
}

a{
  text-decoration: none;
}

.map-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  animation: rodal-fade-enter both ease-in;
  animation-duration: 400ms;
  position: relative;
  z-index: 11;

  @media screen and (max-width: 999px) {
    overflow: auto;
  }

  .main-map-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1900px;
    height: 1080px;
    position: relative;

    @media screen and (max-width: 1919px) {
      margin: 0 auto;
      width: 1800px;
      height: 1012.5px;
    }

    @media screen and (max-width: 1799px) {
      width: 1600px;
      height: 900px;
    }

    @media screen and (max-width: 1599px) {
      width: 1400px;
      height: 787.5px;
    }

    @media screen and (max-width: 1399px) {
      width: 1200px;
      height: 675px;
    }

    @media screen and (max-width: 1199px) {
      width: 1000px;
      height: 529.4px;
    }

    @media screen and (max-width: 999px) {
      width: 800px;
      height: 450px;
    }
  }
}
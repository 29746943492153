
.search-bar{
    position: absolute;
    bottom: 25px;
    left: 50px;
}

.search-bar input{
    width: 428px;
    height: 55px;
    background: #11161C;
    color: #FFFFFF;
    font-size: 24px;
    padding-left: 60px;
    border: 0;
}

.search-bar input:focus{
    outline: none;
}

.search-bar img{
    position: absolute;
    top: 15px;
    left: 8px;
}

@media screen and (max-width: 1799px) {
    .search-bar input{
        width: 300px;
        height: 40px;
    }


    .search-bar img{
        width: 24px;
        top: 10px;
        left: 8px;
    }
}

@media screen and (max-width: 1699px) {

    .search-bar img{
        width: 24px;
        top: 10px;
        left: 8px;
    }
}

@media screen and (max-width: 1399px) {

    .search-bar input{
        width: 220px;
        height: 35px;
        font-size: 16px;
    }


    .search-bar img{
        width: 20px;
        top: 10px;
        left: 8px;
    }
}
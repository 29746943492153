.main-map-container .main-map{
    width: 100%;
    height: 100%;
}


.main-map-container .bland-house{
    position: absolute;
    top: 55px;
    left: 285px;
    background-image: url("../../images/shrine.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 445px;
    height: 473px;
}

.main-map-container .bland-house:hover{
    position: absolute;
    top: 55px;
    left: 285px;
    background-image: url("../../images/shrine_hover.png");
    background-repeat: no-repeat;
    width: 445px;
    height: 473px;
}
.bland-house_banner{
    opacity: 0;
}

.main-map-container .bland-house:hover .bland-house_banner{
    background-image: url("../../images/title_banner.png");
    background-repeat: no-repeat;
    width: 194px;
    height: 49px;
    position: absolute;
    top: 350px;
    left: 120px;
    text-align: center;
    color: #FFFFFF;
    font-size: 25px;
    padding: 3px;
    opacity: 1;
}

.back-icon{
    display: flex;
    align-items: center;
    position: absolute;
    top: 25px;
    left: 20px;
    z-index: 9999;

}

.back-icon p{
    padding: 0;
    margin: 0 0 0 10px;
    font-size: 25px;
    color: #FCFFAF;
}

.back-icon img{
    width: 100%;
    height: 100%;
}
@media screen and (max-width: 1899px){
    .back-icon{
        top: 18px;
    }
}

@media screen and (max-width: 1799px){
    .back-icon{
        top: 15px;
    }
}
@media screen and (max-width: 1699px){
    .main-map-container .bland-house{
        width: 285px;
        height: 300px;
        top: 90px;
        left: 235px;
    }

    .main-map-container .bland-house:hover{
        width: 285px;
        height: 300px;
        top: 90px;
        left: 235px;
    }

    .main-map-container .bland-house:hover .bland-house_banner{
        top: 220px;
        left: 40px;
    }
    .back-icon{
        width: 50px;
    }
    .back-icon p {
        font-size: 20px;
    }
}


.main-map-container .mining-house{
    position: absolute;
    top: 20px;
    left: 1250px;
    background-image: url("../../images/mining.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 567px;
    height: 398px;
}

.main-map-container .mining-house:hover{
    position: absolute;
    top: 20px;
    left: 1250px;
    background-image: url("../../images/mining_hover.png");
    background-repeat: no-repeat;
}

.mining-house_banner{
    opacity: 0;
}

.main-map-container .mining-house:hover .mining-house_banner{
    background-image: url("../../images/title_banner.png");
    background-repeat: no-repeat;
    width: 194px;
    height: 49px;
    position: absolute;
    top: 300px;
    left: 200px;
    text-align: center;
    color: #FFFFFF;
    font-size: 25px;
    padding: 3px;
    opacity: 1;
}

@media screen and (max-width: 1699px){
    .main-map-container .mining-house{
        top: 32px;
        left: 943px;
        width: 390px;
        height: 270px;
    }

    .main-map-container .mining-house:hover{
        top: 32px;
        left: 943px;
        width: 390px;
        height: 270px;
    }

    .main-map-container .mining-house:hover .mining-house_banner{
        top: 190px;
        left: 110px;
    }
}


.main-map-container .upgrade-house{
    position: absolute;
    top: 460px;
    left: 1535px;
    background-image: url("../../images/upgrade_house.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 333px;
    height: 515px;
}

.main-map-container .upgrade-house:hover{
    position: absolute;
    top: 460px;
    left: 1535px;
    background-image: url("../../images/upgrade_house_hover.png");
    background-repeat: no-repeat;
}

.main-map-container .upgrade-house .upgrade-house_banner{
    opacity: 0;
}
.main-map-container .upgrade-house:hover .upgrade-house_banner{
    background-image: url("../../images/title_banner.png");
    background-repeat: no-repeat;
    width: 194px;
    height: 49px;
    position: absolute;
    top: 200px;
    left: 70px;
    text-align: center;
    color: #FFFFFF;
    font-size: 25px;
    padding: 3px;
    opacity: 1;
}

@media screen and (max-width: 1699px){
    .main-map-container .upgrade-house{
        top: 448px;
        left: 1178px;
        width: 220px;
        height: 310px;
    }

    .main-map-container .upgrade-house:hover{
        top: 448px;
        left: 1178px;
        width: 220px;
        height: 310px;
    }

    .main-map-container .upgrade-house:hover .upgrade-house_banner{
        top: 150px;
        left: 10px;
    }
}

@media screen and (max-width: 1399px){
    .back-icon{
        width: 35px;
    }
    .back-icon p {
        font-size: 16px;
    }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  .modal-content {
    background-image: url("../../images/paper.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 980px;
    height: 600px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: relative;


    .modal-pool-content{
      margin-right: 70px;

      p{
        margin: 15px 0 0 0;
      }

      .pool-info{
        color: #000000;
        font-family: 'GillSansC', sans-serif;
        font-size: 15px;
      }

      .icon-update-info{
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
      }
    }

    /******************************************************************************/
    /* Swap Container */

    .swapContainer {
      width: 350px; // 440
      height: 350px;
      margin: 50px auto auto 40px;
      border-radius: 24px;
      padding: 6px;
    }

    .swap-btn{
      display: block;
      width: 110px;
      margin: 0 auto;
    }

    .swapHeader {
      text-align: left;
      padding: 4px 20px 0 20px;
    }

    .swapText {
      color: #000;
      font-size: 20px;
    }

    .gearContainer {
      float: right;
      font-size: 22px
    }

    /******************************************************************************/
    /* Currency Input */
    .currencyInput {

      margin: 10px !important;
      padding: 10px;
      border-radius: 20px;

        input:focus{
          outline: none;
        }

    }
    .currencyInput:hover {
      border-color: #000000 !important;
    }
    ::placeholder {
      opacity: 1;
    }
    .numberContainer {
      text-align: left;
    }
    .spinnerContainer {
      height: 55px;
      line-height: 55px;
    }
    .currencyInputField {
      font-size: 28px;
      display: inline-block;
      width: 60%;
      border: none;
      background-color: transparent;
      border-bottom:2px solid #000000 ;
    }
    .tokenContainer {
      text-align: right;
    }
    .tokenName {
      font-size: 22px;
      display: block;
      color: #000;
    }
    .balanceContainer {
      display: block;
    }
    .balanceAmount {
      width: 100%;
      font-size: 14px;
      display: inline;
      color: #000;
      font-family: 'GillSansC', sans-serif;
    }


    h1{
      margin: 0;
      font-size: 24px;
      text-align: center;
    }

    .close-swap-modal{
      position: absolute;
      top: 90px;
      right: 140px;
      cursor: pointer;
      width: 30px;
      height: 30px;

      img{
        width: 100%;
        height: 100%;
      }
    }

    label{
      color: #FFFFFF;
    }

    select{
      display: inline-block;
      margin: 0 auto;
      border: none;
      background: transparent;
      font-size: 22px;
      font-family: Silkscreen, sans-serif;
    }

    select:focus{
      outline: none;
    }

    option{
      font-size: 12px;
    }

    button {
      margin: 10px 0;
      padding: 10px 20px;
      font-size: 16px;
      border: none;
      border-radius: 4px;
      background-color: lightgray;
      cursor: pointer;

      &:hover {
        background-color: darkgray;
      }
    }

    .total-resources{
      color: #FFFFFF;
    }

    .button-swap{
      position: relative;
      cursor: pointer;

      img{
        width: 100%;
      }
      p{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-45%, -60%);
        font-size: 22px;
        margin: 0;
        color: #FFFFFF;
      }
    }

  }
}

.staking-modal{
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #11161CCC;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.staking-modal.hide{
    display: none;
}

.staking-modal-window{
    display: flex;
    flex-direction: column;
    background-image: url("../../images/upgrade_window.png");
    height: 940px;
    width: 1200px;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
}

.items-list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 90%;
    height: 540px;
    margin: auto;
    overflow: auto;
}

.item-staking-info_name{
    font-size: 20px;
    color: #FFA52E;
    text-align: center;
    margin: 0 0 6px 0;
}

.item-staking-info{
    text-align: center;
    margin: 5px 0 0 0;
    color: #B6B7B7;
    font-size: 18px;
    font-family: 'GillSansC', sans-serif;
    font-weight: normal;
    font-style: normal;
}


.staking-item{
    width: 70px;
    height: 80px;
    background-size: contain;
}

.staking-item img{
    width: 100%;
}

.staking-info-block{
    margin-bottom: 30px;
}

.staking-item_unactive{
    display: flex;
    align-items: center;
    width: 110px;
    height: 120px;
    background-image: url("../../images/cell_unactive.png");
    background-repeat: no-repeat;
    margin: 0 50px;
}

.staking-item_selected{
    display: flex;
    align-items: center;
    width: 110px;
    height: 120px;
    background-image: url("../../images/cell_checked.png");
    background-repeat: no-repeat;
    margin: 0 50px;
}

.sort-block{
    position: absolute;
    top: 110px;
    right: 40px;
    color: #B6B7B7;
    font-size: 14px;

}

.sort-block select{
    width: 150px;
    height: 35px;
    background: #11161c;
    margin-left: 10px;
    border: none;
    color: #fff;
    font-family: Silkscreen, sans-serif;
}

.sort-block select option{
    font-family: Silkscreen, sans-serif;
}

.sort-block select:focus{
    outline: none;
}
.staking-modal-window_list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: 50%;
    margin: 20% 10% 5% 5%;
    overflow: auto;
}

.staking-modal-window_item{
    color: #fff;
    text-align: center;
    height: 225px;
    margin: 0 50px;

}

.staking-modal-window_item p{
    font-size: 13px;
    margin: 0;
    color: #B6B7B7;
}

.staking-modal-window_item .item-name{
    color: #FFA52E;
    margin: 0 0 5px 0;
}


.staking-modal-window_item .item{
    background-image: url("../../images/cell_unactive.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 109px;
    height: 120px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.staking-modal-window_item .item:hover{
    background-image: url("../../images/cell_checked.png");
    background-repeat: no-repeat;
    width: 109px;
    height: 120px;
}

.staking-modal-window_item .item img{
    width: 100%;
    height: 75%;
}

.staking-modal-window-two{
    background-image: url("../../images/upgrade_window_part_3.png");
    height: 940px;
    width: 25%;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    margin-left: 10px;
}

.staking-modal-window-three{
    background-image: url("../../images/window_part_3.png");
    height: 80%;
    width: 40%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 40px;
    position: relative;
}

.no-assets{
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 24px;
}

.close{
    position: absolute;
    width: 40px;
    height: 40px;
    top: 90px;
    right: 375px;
    z-index: 1003;
}

.staking-menu .staking-menu_block{
    font-weight: 100;
    background: #202E3D;
    height: 80px;
    padding: 0 25px 0 10px;
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;
}

.staking-menu_block_active{
    font-weight: 100;
    background: #009DC4;
    height: 80px;
    padding: 0 10px;
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    text-align: start;
    font-size: 22px;
    color: #ffffff;
}

.staking-menu_block_active:first-child, .staking-menu .staking-menu_block:first-child{
    margin-top: 25px;
}

.staking-menu .staking-menu_block p{
    font-size: 22px;
    color: #ffffff;
}

@media screen and (max-width: 1900px){
    .close{
        top: 85px;
    }
}
.close img{
    width: 100%;
    height: 100%;
}

.staking-modal-window-two_title{
    display: flex;
    height: 28%;
    align-items: center;
    justify-content: center;
}

.staking-modal-window-two_title_two{
    display: flex;
    height: 20%;
    align-items: center;
    justify-content: center;
    margin-top:70px;
}

.staking-modal-window-two_title_two h3, .staking-modal-window-two_title h3{
    color: #FFA52E;
    font-size: 30px;
    text-align: center;
    margin: 0;
}

/*.staking-item{*/
/*    background-image: url("../../images/cell_unactive.png");*/
/*    background-size: contain;*/
/*    background-repeat: no-repeat;*/
/*    width: 75px;*/
/*    height: 80px;*/
/*    margin: 0 auto;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/

.staking-item img{
    width: 100%;
    height: 70%;
}

.item-info{
    color: #fff;
    font-size: 14px;
    padding: 20px 40px;
}


.staking-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 155px;
    height: 50px;
    background-size: contain;
    margin: 0 auto;
    position: absolute;
    z-index: 9999;
    top: 80px;
}

.staking-btn img{
    width: 100%;
}

.staking-btn span{
    position: absolute;
    color: #FFFFFF;
    font-size: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.staking-menu{
    display: flex;
    flex-direction: column;
    width: 360px;
    height: 90%;
    margin-top: 50px;
}
@media screen and (max-width: 1899px){
    .staking-modal-window{
        height: 880px;
        width: 1125px
    }

    .staking-modal-window-two{
        height: 880px;
    }

    .items-list{
        width: 95%;
    }

    .item-staking-info{
        margin: 5px 0 0 0;
        font-size: 14px;
    }

    .item-staking-info_name{
        font-size: 18px;
    }

    .staking-btn{
        width: 130px;
        height: 35px;
    }
}

@media screen and (max-width: 1799px){
    .staking-modal-window{
        height: 765px;
        width: 975px;
    }

    .staking-modal-window-two{
        height: 765px;
    }

    .staking-modal-window-two_title_two h3, .staking-modal-window-two_title h3{
        font-size: 25px;
    }

    .close{
        top: 75px;
        right: 310px;
        width: 35px;
        height: 35px;
    }

    .items-list{
        height: 400px;
    }

    .staking-menu{
        width: 295px;
    }

    .staking-menu .bland-menu_block p, .staking-menu_block_active{
        font-size: 18px;
    }

    .staking-menu_block_active:first-child, .staking-menu .staking-menu_block:first-child{
        margin-top: 15px;
    }

    .staking-btn {
        width: 115px;
        height: 15px;
    }

    .staking-btn span{
        font-size: 20px;
    }
}


@media screen and (max-width: 1699px){
    .staking-modal-window_item{
        height: 200px;
        margin: 0 20px;
    }

    .staking-modal-window_item .item{
        width: 90px;
        height: 99px;
    }

    .staking-modal-window_item .item:hover{
        background-image: url("../../images/cell_checked.png");
        background-repeat: no-repeat;
        width: 90px;
        height: 99px;
    }

    .staking-modal-window-two .staking-modal-window-two_title h3{
        color: #FFA52E;
        font-size: 24px;
    }

    .items-list {
        width: 100%;
        height: 330px;
    }


    .staking-item_unactive{
        width: 95px;
        height: 105px;
    }

    .staking-item_selected{
        width: 95px;
        height: 105px;
    }

    .staking-menu .staking-menu_block{
        height: 65px;
    }

    .staking-btn {
        width: 95px;
        height: 0;
    }

    .staking-btn span{
        font-size: 16px;
    }
}

@media screen and (max-width: 1599px){
    .staking-modal-window {
        height: 660px;
        width: 840px;
    }

    .staking-modal-window-two{
        height: 660px;
    }

    .staking-modal-window-two .staking-modal-window-two_title h3{
        font-size: 20px;
    }

    .close{
        top: 65px;
        right: 270px;
        width: 30px;
        height: 30px;
    }

    .items-list{
        width: 85%;
    }


    .staking-item_unactive{
        width: 70px;
        height: 80px;
        margin: 0 50px;
    }

    .staking-item_selected{
        width: 70px;
        height: 80px;
        margin: 0 50px;
    }

    .item-staking-info{
        margin: 5px 0 0 0;
        font-size: 12px;
    }

    .item-staking-info_name{
        font-size: 16px;
    }

    .staking-menu_block_active:first-child, .staking-menu .staking-menu_block:first-child{
        margin-top: 5px;
    }

    .staking-menu{
        width: 260px;
    }
    .bland-menu_block_active:first-child, .staking-menu .bland-menu_block:first-child{
        margin-top: 5px;
    }
    .staking-menu .staking-menu_block p, .staking-menu_block_active{
        font-size: 16px;
    }
}

@media screen and (max-width: 1399px){
    .staking-modal-window {
        height: 565px;
        width: 720px;
    }

    .staking-modal-window-two{
        height: 565px;
    }

    .staking-modal-window-two .staking-modal-window-two_title h3{
        font-size: 16px;
    }

    .close {
        top: 60px;
        right: 225px;
        width: 25px;
        height: 25px;
    }

    .staking-menu{
        width: 215px;
    }
    .staking-menu .staking-menu_block, .staking-menu .staking-menu_block_active{
        height: 60px;
    }
    .staking-menu_block_active:first-child, .staking-menu .staking-menu_block:first-child{
        margin-top: 0;
    }
    .staking-menu .staking-menu_block p, .staking-menu_block_active{
        font-size: 14px;
    }
}
.bland-modal{
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    background: #11161CCC;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.bland-modal.hide{
    display: none;
}

.resourse-item{
    width: 28px;
    height: 20px;
}

.resourse-item img{
    width: 100%;
    height: 100%;
}

.bland-modal_main-window{
    width: 1200px;
}

.bland-modal_window_top{
    background-image: url("../../images/window_part_1.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 435px;
    width: 100%;
    position: relative;
    margin-bottom: 7px;
}

.close-bland{
    position: absolute;
    top: 85px;
    right: 350px;
    z-index: 1002;
    width: 40px;
    height: 40px;
}

.close-bland img{
    width: 100%;
    height: 100%;
}

.bland-modal_window_top .bland-items_list{
    display: flex;
    justify-content: space-between;
    height: 125%;
    align-items: center;
    width: 85%;
    margin: 0 auto;

}

.bland-modal_window_bottom .bland-items_list {
    display: flex;
    flex-wrap: wrap;
    height: 50%;
    justify-content: space-evenly;
    width: 95%;
    margin: 47px auto 0 auto;
}

.bland-paper{
    width: 425px;
    height: 265px;
    background-image: url("../../images/paper.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
}

.bland-paper_info{
    font-size: 20px;
    padding: 66px 76px 14px 76px;
    display: flex;
    justify-content: space-between;
    color: #FFFFFF;
    font-family: 'GillSansC', sans-serif;
    font-weight: normal;
    font-style: normal;
}

.bland-price{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'GillSansC', sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #FFFFFF;
    font-size: 20px;
    width: 100%;
    height: 30px;
}

.bland-price img{
    height: 100%;
}

.bland-price span{
    margin-left: 10px;
}

.bland-paper_info p{
    margin: 0;
}

.blanding-btn{
    position: absolute;
    left: 45%;
    bottom: 0;
    transform: translate(-35%, 0px);
}

.blanding-btn img{
    width: 100%;
}

.blanding-btn span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    font-size: 24px;
    color: #FFFFFF;
}


.bland-item{
    width: 110px;
    height: 120px;
    background-image: url("../../images/cell_active.png");
    background-repeat: no-repeat;
    position: relative;
}

.bland-item_sign{
    color: #B6B7B7;
    font-size: 50px;
}

.bland-item img{
    width: 100%;
}
.item-bland-info_name{
    font-size: 20px;
    color: #FFA52E;
    text-align: center;
    margin: 0 0 6px 0;
}

.item-bland-info{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 5px 0 0 0;
    color: #B6B7B7;
    font-size: 16px;
    font-family: 'GillSansC', sans-serif;
    font-weight: normal;
    font-style: normal;
}

.item-bland-info .puclic-check{

}

.bland-item_unactive{
    width: 110px;
    height: 120px;
    background-image: url("../../images/cell_unactive.png");
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
}

.back-icon_pic{
    display: flex;
    align-items: center;
    position: absolute;
    top: 75px;
    left: 340px;
    z-index: 9999;
    width: 40px;
    cursor: pointer;
}


.back-icon_pic img{
    width: 100%;
    height: 100%;
}

.bland-item_choose{
    width: 112px;
    height: 124px;
    background-image: url("../../images/choose-img.png");
    background-repeat: no-repeat;
}

.bland-item_choose span{
    text-align: center;
    font-size: 18px;
    color: #FCFFAF;
    display: flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
}

.inventory-item{
    margin: 0 50px;
}

.bland-menu{
    display: flex;
    flex-direction: column;
    width: 330px;
    height: 90%;
    margin-top: 50px;
}

.bland-menu .bland-menu_block{
    font-weight: 100;
    background: #202E3D;
    height: 80px;
    padding: 0 25px 0 10px;
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;
}

.bland-menu_block_active{
    font-weight: 100;
    background: #DA6814;
    height: 80px;
    padding: 0 10px;
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    text-align: start;
    font-size: 22px;
    color: #ffffff;
}

.bland-menu_block_active:first-child, .bland-menu .bland-menu_block:first-child{
    margin-top: 25px;
}

.bland-menu .bland-menu_block p{
    font-size: 22px;
    color: #ffffff;
}

.paint-new-picture_list{
    display: flex;
    /*align-items: center;*/
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 90%;
    height: 540px;
    margin: 260px auto 0 auto;
    overflow: auto;
}

.bland-info-block{
    margin-bottom: 30px;
    height: 180px;
}

.pic-modal-window{
    display: flex;
    flex-direction: row;
    background-image: url("../../images/upgrade_window.png");
    height: 940px;
    width: 1200px;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
}


.btn-create-img{
    display: flex;
    flex-direction: column;
    width: 210px;
    height: 180px;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 30px;
}

.btn-create-img .create{
    background-image: url("../../images/squere-btn.png");
    width: 100px;
    height: 100px;
    position: relative;
    cursor: pointer;
}

.btn-create-img .create img{
    width: 100%;
    height: 100%;
}


.btn-create-img .create .text-in-btn{
color: #FFFFFF;
    text-align: center;
}

.btn-create-img .create .text-in-btn .text-top{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 0 0;
}

.btn-create-img .create .text-in-btn .text-top p{
    margin: 0 0 0 5px;
    font-size: 20px;
}

.btn-create-img .create .text-in-btn span{
    font-size: 35px;
}

.btn-create-img .create .text-in-btn .text-top img{
    width: 30px;
    height: 30px;
}




.btn-create-img .create-img{
    display: flex;
    flex-direction: column;
    position: relative;
}





.item-bland-info_name{
    font-size: 20px;
    color: #FFA52E;
    text-align: center;
    margin: 0 0 6px 0;
}

.blanding-item_unactive{
    display: flex;
    align-items: center;
    width: 110px;
    height: 120px;
    background-image: url("../../images/cell_unactive.png");
    background-repeat: no-repeat;
    margin: 0 50px;
}

.review-layer{
    background-color: rgba(0, 0, 0, 0.79);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.review-layer p{
    margin: 0;
    color: white;
    text-align: center;
    font-size: 20px;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.item-bland-info {
    text-align: center;
    margin: 5px 0 0 0;
    color: #B6B7B7;
    font-size: 18px;
    font-family: 'GillSansC', sans-serif;
    font-weight: normal;
    font-style: normal;
}


.bland-modal_window_bottom{
    background-image: url("../../images/window_part_2.png");
    background-size: contain;
    background-repeat: no-repeat;
    height: 620px;
    width: 100%;
    position: relative;
}

.bland-modal_window_bottom .available-items{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    height: 60%;
    margin: 0 auto;
    overflow: auto;
}

.bland-modal_window_bottom .bland-modal_window_bottom-title{
    color: #FCFFAF;
    text-align: center;
    font-size: 35px;
    padding-top: 13px;
}

.bland-modal_window_bottom .bland-modal_window_bottom-title h3{
    margin: 0 0 15px 0;
}

.created-blands-list{
    display: flex;
    flex-wrap: wrap;
    padding: 0 80px;
    margin: 150px 0 0 0;
}

.created-blands-list_item{
    margin: 0 80px 0 0;
}


.created-blands-list_item .name{
    font-size: 20px;
    color: #FFA52E;
    text-align: center;
}

.created-blands-list_item .picture{
    width: 110px;
    height: 120px;
    margin: 12px auto;
}

.created-blands-list_item .picture img{
    width: 100%;
    height: 100%;
}

.created-blands-list .btn-voting{
    width: 146px;
    height: 56px;
    background: #7B0343 0 0 no-repeat padding-box;
    box-shadow: 0 10px 10px #011A33;
    cursor: pointer;
}

.created-blands-list .btn-voting span{
    color: #FFFFFF;
    font-size: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    height: 100%;
}


.created-blands-list_item .name p{
    margin: 0;
}

.schemas{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 60px;
    height: 70%;
    margin-top: 170px;
    overflow: auto;
}

.schemas .schema_block p{
    margin: 7px 0 0 0;
}

.schemas .schema_block{
    display: flex;
    color: #FFFFFF;
    align-items: center;
    background-color: #11161C;
    width: 520px;
    height: 300px;
    justify-content: space-evenly;
    margin-top: 30px;
    padding: 10px 0;
}
.schema_block_left{
    text-align: center;
}

.schema_block_left_img{
    width: 110px;
    height: 120px;
}

.schema_block_left_img img{
    width: 100%;
    height: 100%;
}



.schema_block_left h3{
    margin: 0 0 6px 0;
    color: #FFA52E;
    font-size: 20px;
}


.schema_block_left .amount {
    font-family: 'GillSansC', sans-serif;
    font-weight: normal;
    font-style: normal;
}


.schema_block_right{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    font-family: 'GillSansC', sans-serif;
    font-weight: normal;
    font-style: normal;
}

.schema_block_right .schema_about{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
}

.schema_block_right .schema_bland-btn{
    text-align: center;
position: relative;
}

.schema_bland-btn_inside{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
}

.schema-item-frt, .schema-item-snd{
    display: flex;
    align-items: center;
}

.schema_bland-btn_inside .pic-img{
    width: 50px;
    height: 50px;
    margin: 0 10px;
}

.schema_bland-btn_inside .pic-img img{
    width: 100%;
    height: 100%;
}


.schema-or{
    text-align: center;
    color: #B6B7B7;
}

.schema-or p{
    margin: 0;
}

.schema-price{
    text-align: center;
    position: relative;
}

.schema-price_inside{
    position: absolute;
    top: 10px;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}
.schema-price_inside img{
    margin-right: 10px;
}

@media screen and (max-width: 1899px){
    .bland-modal_main-window{
        width: 1090px;
    }
    .bland-modal_window_top{
        height: 395px;
    }

    .close-bland{
        top: 80px;
        right: 350px;
    }

    .header-upgrade-house{
        left: 1090px;
    }

    .bland-modal_window_bottom{
        height: 565px;
    }

    .bland-modal_window_bottom .available-items{
        width: 100%;
    }

    .item-bland-info{
        margin: 5px 0 0 0;
        font-size: 14px;
    }

    .item-bland-info_name{
        font-size: 18px;
    }

    .bland-paper{
        width: 360px;
        height: 225px;
        background-image: url("../../images/paper.png");
        background-size: contain;
        background-repeat: no-repeat;

    }

    .blanding-btn{
        left: 45%;
    }

    .blanding-btn span{
        font-size: 20px;
    }

    .bland-paper_info{
        font-size: 18px;
        padding: 40px 60px 14px 60px;
    }

    .bland-price{
        font-size: 18px;
    }

    .created-blands-list_item .name{
        font-size: 18px;
    }

    .btn-create-img{
        width: 200px;
        height: 161px;
    }

    .paint-new-picture_list{
        height: 540px;
        margin: 180px auto 0 auto;
    }

    .schemas{
        justify-content: center;
    }

    .schemas .schema_block{
        width: 740px;
    }

    .blanding-item_unactive{
        margin: 0 45px;
    }
}


@media screen and (max-width: 1799px){
    .bland-modal_main-window{
        width: 980px;
    }
    .bland-modal_window_top{
        height: 355px;
    }
    .house-list .house-item:first-child{
        margin-top: 15px;
    }
    .close-bland{
        top: 70px;
        right: 305px;
        width: 35px;
        height: 35px;
    }

    .header-upgrade-house{
        width: 35px;
        height: 35px;
        left: 965px;
    }
    .header-upgrade-house_active{
        width: 150px;
    }
    .header-upgrade-house_active-price{
        top: -12px;
        width: 150px;
    }

    .bland-modal_window_bottom{
        height: 510px;
    }

    .bland-item{
        width: 100px;
        height: 110px;
        background-size: contain;
    }

    .house-list{
        width: 290px;
    }

    .bland-menu{
        width: 295px;
    }

    .bland-menu .bland-menu_block p, .bland-menu_block_active{
        font-size: 18px;
    }

    .bland-menu_block_active:first-child, .bland-menu .bland-menu_block:first-child{
        margin-top: 15px;
    }

    .created-blands-list_item{
        margin-right: 55px;
    }

    .created-blands-list .btn-voting span{
        font-size: 16px;
    }

    .btn-create-img .create{
        width: 85px;
        height: 85px;
    }

    .paint-new-picture_list{
        height: 470px;
        margin: 150px auto 0 auto;
    }

    .btn-create-img{
        width: 170px;
    }

    .blanding-item_unactive{
        margin: 0 35px;
    }

    .schemas{
        margin-top: 140px;
    }

    .inventory-item{
        margin: 0 40px;
    }
}

@media screen and (max-width: 1699px){

    .bland-modal_window_bottom .bland-modal_window_bottom-title h3{
        font-size: 24px;
    }

    .bland-modal_window_bottom .available-items{
        width: 93%;
    }

    .inventory-item{
        margin: 0 40px;
    }

    .bland-item{
        width: 95px;
        height: 105px;
    }

    .bland-menu .bland-menu_block{
        height: 65px;
    }

    .bland-item_choose span{
        font-size: 12px;
    }

    .bland-paper{
        width: 360px;
        height: 225px;
    }

    .blanding-btn{
        left: 45%;
        width: 120px;
    }

    .blanding-btn span{
        font-size: 18px;
    }

    .bland-paper_info{
        padding: 47px 60px 14px 60px;
    }

    .bland-price{
        font-size: 18px;
    }


    .created-blands-list_item .picture{
        width: 95px;
        height: 105px;
    }

    .created-blands-list .btn-voting{
        width: 110px;
        height: 50px;
    }

    .created-blands-list .btn-voting span{
        font-size: 14px;
    }

    .paint-new-picture_list{
        height: 500px;
        margin: 150px auto 0 auto;
    }

    .blanding-item_unactive{
        margin: 0 35px;
    }

    .btn-create-img .create .text-in-btn .text-top p{
        font-size: 16px;
    }

    .btn-create-img .create .text-in-btn span{
        font-size: 40px;
    }

    .btn-create-img{
        width: 165px;
        justify-content: flex-end;
    }

    .review-layer p{
        font-size: 18px;
    }

    .bland-info-block{
        margin-bottom: 20px;
        height: 160px;
    }

}

@media screen and (max-width: 1599px){
    .bland-modal_main-window{
        width: 855px;
    }
    .bland-modal_window_top{
        height: 310px;
    }

    .house-list{
        width: 260px;
    }

    .close-bland{
        top: 65px;
        right: 270px;
        width: 30px;
        height: 30px;
    }

    .header-upgrade-house{
        width: 30px;
        height: 30px;
        left: 850px;
    }

    .header-upgrade-house_active{
        width: 130px;
    }

    .header-upgrade-house_active-price{
        top: -14px;
        width: 140px;
    }

    .bland-modal_window_bottom{
        height: 444px;
    }

    .bland-modal_window_bottom .available-items{
        width: 85%;
    }

    .inventory-item{
        margin: 0 35px;
    }

    .bland-item{
        width: 70px;
        height: 80px;
        background-size: contain;
    }

    .item-bland-info{
        margin: 5px 0 0 0;
        font-size: 12px;
    }

    .item-bland-info_name{
        font-size: 13px;
    }

    .house-list .house-item:first-child{
        margin-top: 7px;
    }

    .bland-menu{
        width: 260px;
    }
    .bland-menu_block_active:first-child, .bland-menu .bland-menu_block:first-child{
        margin-top: 5px;
    }
    .bland-menu .bland-menu_block p, .bland-menu_block_active{
        font-size: 16px;
    }

    .bland-paper{
        width: 295px;
        height: 185px;
    }

    .bland-paper_info{
        padding: 38px 55px 8px 55px;
        font-size: 16px;
    }

    .bland-price{
        font-size: 16px;
        height: 18px;
    }

    .created-blands-list{
        padding: 45px;
        margin: 70px 0 0 0;
    }

    .created-blands-list_item .picture{
        width: 70px;
        height: 80px;
    }

    .created-blands-list_item .name{
        font-size: 13px;
    }

    .btn-create-img{
        width: 140px;
        height: 121px;
    }

    .paint-new-picture_list{
        height: 400px;
    }
    .btn-create-img{
        width: 140px;
        height: 121px;
    }

    .btn-create-img .create{
        width: 75px;
        height: 75px;
    }

    .btn-create-img .create .text-in-btn .text-top img{
        width: 20px;
        height: 20px;
    }

    .btn-create-img .create .text-in-btn span{
        font-size: 30px;
    }

    .schemas{
        margin-top: 120px;
    }

    .review-layer p{
        font-size: 14px;
    }

    .bland-info-block{
        margin-bottom: 5px;
        height: 130px;
    }
}

@media screen and (max-width: 1399px){
    .bland-modal_main-window{
        width: 720px;
    }

    .bland-modal_window_top{
        height: 262px;
    }

    .close-bland{
        width: 25px;
        height: 25px;
        top: 55px;
        right: 225px;
    }

    .header-upgrade-house{
        width: 25px;
        height: 25px;
        left: 730px;
    }

    .header-upgrade-house_active{
        width: 110px;
    }

    .header-upgrade-house_active-price{
        top: -13px;
        left: 10px;
        font-size: 14px;
        width: 110px;
    }

    .house-list{
        width: 210px;
    }

    .house-list .house-item p, .house-item_active p{
        font-size: 12px;
    }

    .house-list .house-item h4, .house-item_active h4{
        font-size: 16px;
    }

    .house-list .house-item:first-child{
        margin-top: -2px;
    }

    .bland-modal_window_bottom .bland-modal_window_bottom-title h3{
        font-size: 21px;
    }

    .bland-modal_window_bottom{
        height: 374px;
    }

    .inventory-item{
        margin: 0 30px;
    }

    .bland-item{
        width: 60px;
        height: 70px;
        background-size: contain;
    }

    .house-list .house-item{
        height: 60px;
    }

    .bland-menu{
        width: 215px;
    }
    .bland-menu .bland-menu_block, .bland-menu .bland-menu_block_active{
        height: 60px;
    }
    .bland-menu_block_active:first-child, .bland-menu .bland-menu_block:first-child{
        margin-top: 0;
    }
    .bland-menu .bland-menu_block p, .bland-menu_block_active{
        font-size: 14px;
    }

    .created-blands-list_item{
        margin-right: 30px;
    }

    .bland-paper{
        width: 255px;
        height: 160px;
    }

    .blanding-btn{
        left: 45%;
        bottom: -20px;
        width: 95px;
    }

    .blanding-btn span{
        font-size: 16px;
    }

    .paint-new-picture_list{
        height: 325px;
    }

    .blanding-item_unactive{
        margin: 0 25px;
    }

    .created-blands-list_item .picture{
        width: 60px;
        height: 70px;
    }

    .schemas{
        margin-top: 100px;
    }

    .btn-create-img{
        width: 110px;
    }

    .btn-create-img .create{
        width: 70px;
        height: 70px;
    }

    .btn-create-img .create .text-in-btn span {
        font-size: 28px;
    }

    .schemas .schema_block{
        height: 280px;
    }

    .review-layer p{
        font-size: 12px;
    }
}

.voting-wrapper{
    margin: 200px 0 0 35px;
    height: 65%;
    overflow: auto;
}

.voting-block_container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.voting-main-img img{
    width: 100%;
    height: 100%;
}

.price-vote{
    display: flex;
    align-items: center;
    color: #FFFFFF;
    font-family: 'GillSansC', sans-serif;
}

.price-vote img{
    margin-right: 15px;
}

.voting-block_container .vote-btn_contant button{
    background-color: #7B0343;
    width: 125px;
    height: 40px;
    color: #FFFFFF;
    font-size: 20px;
    font-family: Silkscreen, sans-serif;
}

.voting-block{
    display: flex;
    width: 800px;
    height: 197px;
    background: #181F27 0 0 no-repeat padding-box;
    justify-content: space-around;
    font-family: 'GillSansC', sans-serif;
    align-items: center;
    margin-bottom: 10px;
}

.voting-block p{
    color: #FFFFFF;
}

.voting-block span{
    color: #FFA52E;
}

.voting-main-img{
    text-align: center;
    width: 110px;
}

.voting-main-img p{
    color: #FFA52E;
    font-size: 20px;
    margin: 0 0 5px 0;
}

.voting-info{
    width: 35%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.voting-info .info{
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 2px solid #900A51;
}

.voting-info .info .voting-info_left, .voting-info .info .voting-info_right{
    display: flex;
    flex-direction: column;
}

.voting-info .info .voting-info_right{
    text-align: end;
}

.voting-info .info .voting-info_left p, .voting-info .info .voting-info_right p{
    margin: 0;
}

.blend{
    display: flex;
    justify-content: space-between;
}

.blend .item-one, .blend .item-two{
    display: flex;
    align-items: center;

}

.blend .item-one img, .blend .item-two img{
    width: 44px;
    height: 34px;
    margin-right: 15px;
}

.voting-percent span{
    font-size: 45px;
    display: block;
    text-align: center;
    margin: 0 auto;
}

.voting-percent p{
    font-size: 18px;
    text-align: center;
}


@media screen and (max-width: 1799px){
    .voting-block {
        width: 650px;
    }

    .voting-main-img img{
        width: 100%;
        height: 100%;
    }

    .voting-percent span{
        font-size: 40px;
    }
}

@media screen and (max-width: 1699px){
    .voting-wrapper{
        margin: 150px 0 0 60px;
    }
}

@media screen and (max-width: 1599px){
    .voting-wrapper{
        margin: 140px 0 0 40px;
    }

    .voting-block {
        width: 570px;
        height: 160px;
    }

    .info{
        font-size: 12px;
    }

    .voting-percent p{
        font-size: 15px;
    }

    .voting-main-img img{
        width: 70px;
    }

    .voting-percent span{
        font-size: 30px;
    }

    .blend .item-one img, .blend .item-two img{
        width: 20px;
        height: 22px;
    }
}

.header{
    background-image: url("../../images/top-bar.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    height: 12%;
    width: 100%;
    z-index: 1001;
}
.header .header-title{
    margin: 22px 0 12px 0;
    text-align: center;
    color: #FFA52E;
    font-size: 35px;
}

.header .header-subtitle{
    text-align: center;
    color: #fff;
    font-size: 20px;
}

.header .header-subtitle span{
    color: #FFA52E;
}

.header .staking-btn{
    width: 100%;
}


.header-upgrade-house{
    position: absolute;
    bottom: 5px;
    left: 1160px;
    width: 40px;
    height: 40px;
}

.header-upgrade-house .header-upgrade-house_disabled{
    width: 100%;
    height: 100%;
}

.user-dao{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 50px;
    font-family: 'GillSansC', sans-serif;
    font-weight: normal;
    font-style: normal;
}

.user-dao p{
    color: #ffffff;
    font-size: 20px;
}

.user-dao img{
    margin-right: 10px;
    width: 30px;
    height: 30px;
}




.header-upgrade-house_active {
    position: absolute;
    left: 0;
    width: 180px;
}


.header-upgrade-house_active-price{
    position: absolute;
    top: -8px;
    color: #FFFFFF;
    width: 180px;
    text-align: center;
}

.header .logo-map{
    display: block;
    margin: 0 auto;
    padding: 16px 0;
    width: 145px;
}

.header-right{
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 18px;
}

.market-btn{
    position: relative;
    width: 155px;
}

.market-btn span{
    position: absolute;
    top: 0;
    left: 0;
    color: #FFFFFF;
    font-size: 26px;
    width: 100%;
    text-align: center;
    padding: 5px 0 5px 0;
}

.market-btn img{
    width: 100%;
    height: 100%;
}

.header-btns img:last-child{
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1899px) {
    .market-btn{
        width: 120px;
    }

    .market-btn span{
        font-size: 20px;
    }

    .user-dao p{
        font-size: 17px;
    }
}

@media screen and (max-width: 1799px) {
    .header .logo-map{
        padding: 19px 0;
        width: 115px;
    }
    .header .header-title{
        font-size: 30px;
    }
    .header .header-subtitle{
        text-align: center;
        color: #fff;
        font-size: 16px;
    }
}

@media screen and (max-width: 1699px) {

    .header .header-title{
        font-size: 24px;
    }

    .market-btn{
        width: 110px;
    }

    .market-btn span{
        font-size: 17px;
    }

    .header-right{
        top: 10px;
    }

    .user-dao p{
        font-size: 12px;
    }
}

@media screen and (max-width: 1599px) {
    .header .logo-map{
        padding: 19px 0;
        width: 95px;
    }

    .header .header-subtitle{
        font-size: 14px;
    }
}

@media screen and (max-width: 1399px) {
    .header .logo-map{
        padding: 13px 0;
        width: 85px;
    }

    .header .header-title{
        font-size: 21px;
    }

    .header .header-subtitle{
        font-size: 12px;
    }
}

@media screen and (max-width: 1299px) {
    .header-btns{
        top: 10px;
    }

    .market-btn{
        width: 100px;
    }

    .market-btn span{
        font-size: 16px;
    }
}

@media screen and (max-width: 1099px) {
    .header .logo-map{
        padding: 10px 0;
        width: 70px;
    }
}

.header .resourses-list{
    position: absolute;
    top: 10px;
    left: 220px;
    display: flex;
    font-family: 'GillSansC', sans-serif;
    font-weight: normal;
    font-style: normal;
}

.header .resourses-list .item{
    display: flex;
    align-items: center;
    margin-left: 15px;
    font-size: 20px;
    color: #FFFFFF;
}
.header .resourses-list .item:first-child{
    display: flex;
    align-items: center;
    margin-left: 0;
    font-size: 20px;
    color: #FFFFFF;
}

.header .resourses-list .item p{
    margin-left: 10px;
}

@media screen and (max-width: 1899px){

    .header .resourses-list{
        top: 12px;
    }
    .header .resourses-list .item, .header .resourses-list .item:first-child{
        font-size: 17px;
    }
}

@media screen and (max-width: 1899px){

    .header .resourses-list{
        top: 10px;
    }

}


@media screen and (max-width: 1699px){

    .header .resourses-list{
        top: 15px;
    }

    .header .resourses-list .item, .header .resourses-list .item:first-child{
        font-size: 12px;
    }

}

@media screen and (max-width: 1399px){

    .header .logo-map{
        width: 78px;
    }

    .header .logo-map{
        padding: 18px 0;
    }
    .header .resourses-list .item, .header .resourses-list .item:first-child{
        font-size: 12px;
    }

    .header .resourses-list{
        top: 5px;
        left: 180px;
    }

    .header-right{
        top: 7px;
    }

    .market-btn{
        width: 95px;
    }

    .market-btn span{
        font-size: 15px;
    }


    .user-dao img{
        width: 25px;
        height: 25px;
    }

}
.maining-modal{
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    background: #11161CCC;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.maining-modal.hide{
    display: none;
}

.maining-modal_mining-stats{
    display: flex;
    flex-direction: column;
    padding-left: 60px;
    margin-top: 360px;
}

.maining-modal_mining-stats h5{
    color: #FFA52E;
    font-size: 24px;
    margin: 0 0 10px 0;
    font-weight: 100;
}

.maining-modal_mining-stats .stat{
    display: flex;
    align-items: center;
    margin-bottom: 7px;
}

.maining-modal_mining-stats .stat p{
    color: #FFF;
    font-size: 18px;
    margin: 7px 10px 0 0;
}

.resourse-item{
    width: 28px;
    height: 20px;
}
.resourse-item img{
    width: 100%;
    height: 100%;
}

.maining-modal_main-window{
    margin-left: 100px;
    width: 1200px;
}

.maining-modal_window_top{
    background-image: url("../../images/window_part_1.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 435px;
    width: 100%;
    position: relative;
    margin-bottom: 7px;
}

.close-mining{
    position: absolute;
    top: 85px;
    right: 350px;
    z-index: 1002;
    width: 40px;
    height: 40px;
}

.close-mining img{
    width: 100%;
    height: 100%;
}

.maining-modal_window_top .mining-items_list{
    display: flex;
    justify-content: space-evenly;
    height: 120%;
    align-items: center;
    width: 70%;
    margin: 0 auto;

}

.maining-modal_window_bottom .mining-items_list {
    display: flex;
    flex-wrap: wrap;
    height: 50%;
    justify-content: space-evenly;
    width: 95%;
    margin: 47px auto 0 auto;
}

.mining-item{
    width: 110px;
    height: 120px;
    background-image: url("../../images/cell_active.png");
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
}

.mining-item img{
    width: 100%;
}

.mining-info-block{
    margin-bottom: 30px;
}

.mining-item-block{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 110px;
}

.item-mining-info_name{
    font-size: 20px;
    color: #FFA52E;
    text-align: center;
    margin: 0 0 6px 0;
}

.item-mining-info{
    text-align: center;
    margin: 5px 0 0 0;
    color: #B6B7B7;
    font-size: 18px;
    font-family: 'GillSansC', sans-serif;
    font-weight: normal;
    font-style: normal;
}

.mining-item_unactive{
    width: 110px;
    height: 120px;
    background-image: url("../../images/cell_unactive.png");
    background-repeat: no-repeat;
}

/*.inventory-item{*/
/*    margin: 0 50px;*/
/*}*/

.house-list{
    display: flex;
    flex-direction: column;
    width: 330px;
    height: 90%;
    margin-top: 50px;
}

.house-list .house-item{
    font-weight: 100;
    background: #202E3D;
    height: 80px;
    padding: 0 25px 0 5px;
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

.house-list .house-item:first-child{
    margin-top: 25px;
}
.house-item_active{
    font-weight: 100;
    background: #7B0243;
    height: 170px;
    padding: 0 10px;
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    cursor: pointer;
}

.house-item_active:first-child{
    margin-top: 25px;
}

.house-list .house-item h4, .house-item_active h4{
    color: #FCFFAF;
    text-align: end;
    font-size: 24px;
    margin: 0;
}

.house-list .house-item p, .house-item_active p{
    color: #FFFFFF;
    text-align: end;
    font-size: 16px;
    margin: 0;
    font-family: 'GillSansC', sans-serif;
    font-weight: normal;
    font-style: normal;
}



.house-item_active h4{
    padding-top: 10px;
}
.faction span, .rare span {
    color: #FFA52E;
}

.house-item_active .faction, .house-item_active .rare:first-child{
    margin-right: 32px;
}

.house-item_active .faction{
    margin-top: 40px;
}

.house-item_active .faction, .house-item_active .rare{
    font-size: 16px;
    text-align: start;
}

.header-upgrade-house_active-price{
    cursor: pointer;
}

.maining-modal_window_bottom{
    background-image: url("../../images/window_part_2.png");
    background-size: contain;
    background-repeat: no-repeat;
    height: 620px;
    width: 100%;
    position: relative;
}

.maining-modal_window_bottom .available-items{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    height: 60%;
    margin: 0 auto;
    overflow: auto;
}

.maining-modal_window_bottom .maining-modal_window_bottom-title{
    color: #FCFFAF;
    text-align: center;
    font-size: 35px;
    padding-top: 13px;
}

.maining-modal_window_bottom .maining-modal_window_bottom-title h3{
    margin: 0 0 15px 0;
}

@media screen and (max-width: 1899px){
    .maining-modal_main-window{
        width: 1090px;
    }
    .maining-modal_window_top{
        height: 395px;
    }

    .close-mining{
        top: 80px;
        right: 350px;
    }

    .header-upgrade-house{
        left: 1090px;
    }

    .maining-modal_window_bottom{
        height: 565px;
    }

    .maining-modal_window_bottom .available-items{
        width: 100%;
    }

    .item-mining-info{
        margin: 5px 0 0 0;
        font-size: 14px;
    }

    .item-mining-info_name{
        font-size: 18px;
    }
}


@media screen and (max-width: 1799px){
    .maining-modal_main-window{
        width: 980px;
        margin-left: 65px;
    }
    .maining-modal_window_top{
        height: 355px;
    }
    .house-list .house-item:first-child{
        margin-top: 15px;
    }
    .close-mining{
        top: 70px;
        right: 305px;
        width: 35px;
        height: 35px;
    }

    .header-upgrade-house{
        width: 35px;
        height: 35px;
        left: 965px;
    }
    .header-upgrade-house_active{
        width: 150px;
    }
    .header-upgrade-house_active-price{
        top: -12px;
        width: 150px;
    }

    .maining-modal_window_bottom{
        height: 510px;
    }

    .mining-item{
        width: 100px;
        height: 110px;
        background-size: contain;
    }

    .house-list{
        width: 290px;
    }

    .house-item_active:first-child{
        margin-top: 15px;
    }
}

@media screen and (max-width: 1699px){

    .maining-modal_window_bottom .maining-modal_window_bottom-title h3{
        font-size: 24px;
    }

    .maining-modal_window_bottom .available-items{
        width: 93%;
    }

    .inventory-item{
        margin: 0 40px;
    }

    .mining-item{
        width: 95px;
        height: 105px;
    }

    .house-list .house-item{
        height: 65px;
    }

    .house-list .house-item h4, .house-item_active h4{
        font-size: 20px;
    }
    .house-list .house-item p, .house-item_active p{
        font-size: 14px;
    }

    .house-item_active{
        height: 150px;
    }

    .house-item_active .faction{
        margin-top: 25px;
    }


}

@media screen and (max-width: 1599px){
    .maining-modal_main-window{
        width: 855px;
        margin-left: 25px;
    }
    .maining-modal_window_top{
        height: 310px;
    }

    .house-list{
        width: 260px;
    }

    .close-mining{
        top: 65px;
        right: 270px;
        width: 30px;
        height: 30px;
    }

    .header-upgrade-house{
        width: 30px;
        height: 30px;
        left: 850px;
    }

    .header-upgrade-house_active{
        width: 130px;
    }

    .header-upgrade-house_active-price{
        top: -14px;
        width: 140px;
    }

    .maining-modal_mining-stats h5{
        font-size: 20px;
    }

    .maining-modal_mining-stats .stat p{
        font-size: 14px;
    }

    .maining-modal_main-window{
        margin-left: 55px;
    }
    .maining-modal_window_bottom{
        height: 444px;
    }

    .maining-modal_window_bottom .available-items{
        width: 85%;
    }

    .inventory-item{
        margin: 0 35px;
    }

    .mining-item{
        width: 70px;
        height: 80px;
        background-size: contain;
    }

    .item-mining-info{
        margin: 5px 0 0 0;
        font-size: 12px;
    }

    .item-mining-info_name{
        font-size: 13px;
    }

    .house-list .house-item:first-child{
        margin-top: 7px;
    }

    .house-item_active:first-child{
        margin-top: 5px;
    }

    .house-item_active .faction, .house-item_active .rare{
        font-size: 14px;
    }
}

@media screen and (max-width: 1399px){
    .maining-modal_mining-stats{
        margin-top: 260px;
    }

    .maining-modal_mining-stats h5{
        font-size: 18px;
    }

    .maining-modal_mining-stats .stat p{
        font-size: 13px;
    }

    .maining-modal_main-window{
        width: 720px;
    }

    .maining-modal_window_top{
        height: 262px;
    }

    .close-mining{
        width: 25px;
        height: 25px;
        top: 55px;
        right: 225px;
    }

    .header-upgrade-house{
        width: 25px;
        height: 25px;
        left: 730px;
    }

    .header-upgrade-house_active{
        width: 110px;
    }

    .header-upgrade-house_active-price{
        top: -13px;
        left: 10px;
        font-size: 14px;
        width: 110px;
    }

    .house-list{
        width: 215px;
    }

    .house-list .house-item p, .house-item_active p{
        font-size: 12px;
    }

    .house-list .house-item h4, .house-item_active h4{
        font-size: 16px;
    }

    .house-list .house-item:first-child{
        margin-top: -2px;
    }

    .maining-modal_window_bottom .maining-modal_window_bottom-title h3{
        font-size: 21px;
    }

    .maining-modal_window_bottom{
        height: 374px;
    }

    .inventory-item{
        margin: 0 30px;
    }

    .mining-item{
        width: 60px;
        height: 70px;
        background-size: contain;
    }

    .house-list .house-item{
        height: 60px;
    }

    .house-item_active{
        height: 125px;
    }

    .house-item_active:first-child{
        margin-top: -5px;
    }

    .house-item_active .faction, .house-item_active .rare{
        font-size: 11px;
    }

    .upgrade-item-info{
        font-size: 12px;
    }


}
.upgrade-modal{
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #11161CCC;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.upgrade-modal.hide{
    display: none;
}

.upgrade-modal-window{
    display: flex;
    flex-direction: column;
    background-image: url("../../images/upgrade_window.png");
    height: 940px;
    width: 1200px;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    overflow: auto;
}

.update-items-list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    height: 540px;
    margin: auto;
    overflow: auto;
}

.item-upgrade-info_name{
    font-size: 20px;
    color: #FFA52E;
    text-align: center;
    margin: 0 0 6px 0;
}

.item-upgrade-info{
    text-align: center;
    margin: 5px 0 0 0;
    color: #B6B7B7;
    font-size: 18px;
    font-family: 'GillSansC', sans-serif;
    font-weight: normal;
    font-style: normal;
}


.upgrade-item{
    width: 70px;
    height: 80px;
    background-size: contain;
}

.upgrade-item img{
    width: 100%;
}

.upgrade-info-block{
    margin-bottom: 30px;
}

.upgrade-item_unactive{
    display: flex;
    align-items: center;
    width: 110px;
    height: 120px;
    background-image: url("../../images/cell_unactive.png");
    background-repeat: no-repeat;
    margin: 0 50px;
}

.upgrade-item_selected{
    display: flex;
    align-items: center;
    width: 110px;
    height: 120px;
    background-image: url("../../images/cell_checked.png");
    background-repeat: no-repeat;
    margin: 0 50px;
}

.sort-block{
    position: absolute;
    top: 110px;
    right: 40px;
    color: #B6B7B7;
    font-size: 14px;

}

.sort-block select{
    width: 150px;
    height: 35px;
    background: #11161c;
    margin-left: 10px;
    border: none;
    color: #fff;
    font-family: Silkscreen, sans-serif;
}

.sort-block select option{
    font-family: Silkscreen, sans-serif;
}

.sort-block select:focus{
    outline: none;
}
.upgrade-modal-window_list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: 50%;
    margin: 20% 10% 5% 5%;
    overflow: auto;
}

.upgrade-modal-window_item{
    color: #fff;
    text-align: center;
    height: 225px;
    margin: 0 50px;

}

.upgrade-modal-window_item p{
    font-size: 13px;
    margin: 0;
    color: #B6B7B7;
}

.upgrade-modal-window_item .item-name{
    color: #FFA52E;
    margin: 0 0 5px 0;
}


.upgrade-modal-window_item .item{
    background-image: url("../../images/cell_unactive.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 109px;
    height: 120px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upgrade-modal-window_item .item:hover{
    background-image: url("../../images/cell_checked.png");
    background-repeat: no-repeat;
    width: 109px;
    height: 120px;
}

.upgrade-modal-window_item .item img{
    width: 100%;
    height: 75%;
}

.upgrade-modal-window-two{
    background-image: url("../../images/upgrade_window_part_3.png");
    height: 940px;
    width: 455px;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
}

.upgrade-modal-window-three{
    background-image: url("../../images/window_part_3.png");
    height: 940px;
    width: 455px;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    background-position: right;
}

.no-assets{
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 24px;
}

.upgrade-item-info{
    color: #fff;
    font-size: 22px;
    padding: 32px 75px 130px 75px;
    font-family: 'GillSansC', sans-serif;
    font-weight: normal;
    font-style: normal;
}

.upgrade-item-info .production, .upgrade-item-info .minted, .upgrade-item-info .max-levels, .upgrade-item-info .faction{
    display: flex;
    justify-content: space-between;
}

.upgrade-item-info p{
    margin: 10px 0 0 0;
}

.upgrade-item-info .production p:last-child{
    color: #FCFFAF;
}

.time-to-upgrade{
    color: #FFFFFF;
    text-align: center;
}

.upg-price{
    display: flex;
    align-items: center;
    justify-content: center;
}

.upg-price p{
    font-family: 'GillSansC', sans-serif;
}

.upg-price img{
    margin-right: 10px;
}


/*.time-to-upgrade p:last-child{*/
/*    margin-top: 10px;*/
/*}*/


.time-to-upgrade p{
    margin: 5px;
}

.close-upgrade{
    position: absolute;
    width: 40px;
    height: 40px;
    top: 85px;
    right: 30px;
    z-index: 1003;
}

@media screen and (max-width: 1900px){
    .close-upgrade{
        top: 85px;
    }
}
.close-upgrade img{
    width: 100%;
    height: 100%;
}

.upgrade-modal-window-two_title{
    display: flex;
    height: 28%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.upgrade-modal-window-two_title_two{
    display: flex;
    height: 20%;
    width: 455px;
    align-items: center;
    justify-content: center;
    margin-top:70px;
}

.upgrade-modal-window-two_title_two h3, .upgrade-modal-window-two_title h3{
    color: #FFA52E;
    font-size: 28px;
    text-align: center;
    margin: 0;
}

.upgradeing-item{
    background-image: url("../../images/cell_unactive.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 110px;
    height: 120px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upgradeing-item img{
    width: 100%;
}


.upgrade-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.upgrade-btn{
    background-image: url("../../images/green_btn.png");
    background-repeat: no-repeat;
    width: 155px;
    height: 50px;
    background-size: contain;
    margin: 0 auto;
}
.upgrade-btn:hover{
    background-image: url("../../images/green_btn_hover.png");
    background-repeat: no-repeat;
}

.upgrade-btn span{
    position: absolute;
    color: #FFFFFF;
    font-size: 24px;
}
@media screen and (max-width: 1899px){
    .upgrade-modal-window{
        height: 880px;
        width: 1125px
    }

    .upgrade-modal-window-two{
        width: 400px;
        height: 880px;
    }

    .upgrade-modal-window-three{
        width: 400px;
        height: 930px;
        margin: 0;
    }

    .update-items-list{
        width: 95%;
    }

    .item-upgrade-info{
        margin: 5px 0 0 0;
        font-size: 14px;
    }

    .item-upgrade-info_name{
        font-size: 16px;
    }
}

@media screen and (max-width: 1799px){
    .upgrade-modal-window{
        height: 765px;
        width: 975px;
    }

    .upgrade-modal-window-two{
        width: 370px;
        height: 765px;
    }

    .upgrade-modal-window-three{
        width: 370px;
        height: 765px;
    }


    .upgrade-modal-window-two_title_two{
        width: 100%;
    }

    .upgrade-modal-window-two_title_two h3, .upgrade-modal-window-two_title h3{
        font-size: 25px;
    }

    .close-upgrade{
        top: 75px;
        width: 35px;
        height: 35px;
    }

    .upgrade-item_unactive{
        width: 90px;
        height: 100px;
        margin: 0 40px;
    }

    .upgrade-item_selected{
        width: 90px;
        height: 100px;
        margin: 0 40px;
    }

    .update-items-list{
        height: 400px;
    }

    .upgradeing-item{
        width: 90px;
        height: 100px;
    }

    .upgrade-item-info{
        font-size: 20px;
        padding: 32px 60px 110px 40px;
    }
}


@media screen and (max-width: 1699px){
    .upgrade-modal-window_item{
        height: 200px;
        margin: 0 20px;
    }

    .upgrade-modal-window_item .item{
        width: 90px;
        height: 99px;
    }

    .upgrade-modal-window_item .item:hover{
        background-image: url("../../images/cell_checked.png");
        background-repeat: no-repeat;
        width: 90px;
        height: 99px;
    }

    .upgrade-modal-window-two .upgrade-modal-window-two_title h3{
        color: #FFA52E;
        font-size: 24px;
    }

    .update-items-list {
        width: 95%;
        height: 330px;
    }


    .upgrade-item_unactive{
        width: 95px;
        height: 105px;
    }

    .upgrade-item_selected{
        width: 95px;
        height: 105px;
    }

    .upgrade-modal-window-three{
        height: 765px;
    }

    .upgrade-item-info{
        font-size: 18px;
        padding: 25px 70px 100px 45px;
    }

    .upgrade-modal-window-two_title_two h3, .upgrade-modal-window-two_title h3{
        font-size: 22px;
    }

}

@media screen and (max-width: 1599px){
    .upgrade-modal-window {
        height: 660px;
        width: 840px;
    }

    .upgrade-modal-window-two{
        height: 660px;
    }

    .upgrade-modal-window-two .upgrade-modal-window-two_title h3{
        font-size: 20px;
    }

    .close-upgrade{
        top: 65px;
        right: 15px;
        width: 30px;
        height: 30px;
    }

    .update-items-list{
        width: 85%;
    }

    .upgrade-modal-window-two{
        width: 320px;
    }

    .upgrade-modal-window-three{
        width: 320px;
    }




    .upgrade-item_unactive{
        width: 70px;
        height: 80px;
        margin: 0 50px;
    }

    .upgrade-item_selected{
        width: 70px;
        height: 80px;
        margin: 0 50px;
    }

    .item-upgrade-info{
        margin: 5px 0 0 0;
        font-size: 12px;
    }

    .item-upgrade-info_name{
        font-size: 16px;
    }

    .upgrade-modal-window-three{
        height: 660px;
    }

    .upgradeing-item{
        width: 70px;
        height: 80px;
    }

    .upgrade-item-info{
        font-size: 16px;
        padding: 25px 70px 25px 45px;
    }

    .upgrade-modal-window-two_title_two h3, .upgrade-modal-window-two_title h3{
        font-size: 22px;
    }

    .upgrade-btn{
        width: 110px;
        height: 36px;
    }

    .upgrade-btn span{
        font-size: 16px;
    }
}

@media screen and (max-width: 1399px){
    .upgrade-modal-window {
        height: 565px;
        width: 720px;
    }

    .upgrade-modal-window-two{
        height: 565px;
    }

    .upgrade-modal-window-two .upgrade-modal-window-two_title h3{
        font-size: 16px;
    }

    .close-upgrade {
        top: 55px;
        right: 10px;
        width: 25px;
        height: 25px;
    }

    .update-items-list{
        width: 95%;
    }

    .upgrade-modal-window-two{
        width: 275px;
    }

    .upgrade-modal-window-three{
        width: 275px;
        height: 565px;
    }

    .upgrade-item-info{
        font-size: 12px;
        padding: 25px 50px 30px 45px;
    }
}


 .building {
    position: absolute;
    z-index: 12;
    transition: .3s;


    @media screen and (max-width: 1919px) {
      transform: scale(0.85);
    }

    @media screen and (max-width: 1799px) {
      transform: scale(0.70);
    }

    @media screen and (max-width: 1599px) {
      transform: scale(0.65);
    }

    @media screen and (max-width: 1399px) {
      transform: scale(0.50);
    }

    @media screen and (max-width: 1199px) {
      transform: scale(0.45);
    }

    @media screen and (max-width: 999px) {
      transform: scale(0.4);
    }
  }

  .building-1 {
    left: 280px;
    top: 55px;

    &:hover{
      background: url("../images/shrine_hover.png");
    }

    &:hover .banner-1{
      opacity: 1;
    }

    @media screen and (max-width: 1919px) {
      left: 250px;
      top: 55px;
    }

    @media screen and (max-width: 1799px) {
      left: 195px;
      top: 0;
    }

    @media screen and (max-width: 1599px) {
      left: 145px;
      top: -20px;
    }

    @media screen and (max-width: 1399px) {
      left: 95px;
      top: -60px;
    }

    @media screen and (max-width: 1199px) {
      left: 147px;
      top: 26px;
    }

    @media screen and (max-width: 999px) {
      left: 244px;
      top: 99px;
    }
  }

  .building-2 {
    left: 1255px;
    top: 35px;

    &:hover{
      background: url("../images/mining_hover.png");
    }

    @media screen and (max-width: 1919px) {
      left: 1160px;
      top: 20px;
    }

    @media screen and (max-width: 1799px) {
      left: 990px;
      top: -15px;
    }

    @media screen and (max-width: 1599px) {
      left: 840px;
      top: -35px;
    }

    @media screen and (max-width: 1399px) {
      left: 680px;
      top: -60px;
    }

    @media screen and (max-width: 1199px) {
      left: 393px;
      top: 90px;
    }

    @media screen and (max-width: 999px) {
      left: 296px;
      top: 59px;
    }
  }

  .building-3 {
    left: 1530px;
    top: 560px;

    &:hover{
      background: url("../images/upgrade_house_hover.png");
    }

    @media screen and (max-width: 1919px) {
      left: 1440px;
      top: 520px;
    }

    @media screen and (max-width: 1799px) {
      left: 1260px;
      top: 455px;
    }

    @media screen and (max-width: 1599px) {
      left: 1079px;
      top: 360px;
    }

    @media screen and (max-width: 1399px) {
      left: 895px;
      top: 270px;
    }

    @media screen and (max-width: 1199px) {
      left: 455px;
      top: 115px;
    }

    @media screen and (max-width: 999px) {
      left: 343px;
      top: 84px;
    }
  }

 .building-4 {
   left: 40px;
   top: 430px;

   &:hover{
     background: url("../images/shrine_hover.png");
   }

   @media screen and (max-width: 1919px) {
     left: 40px;
     top: 430px;
   }

   @media screen and (max-width: 1799px) {
     left: -20px;
     top: 340px;
   }

   @media screen and (max-width: 1599px) {
     left: -20px;
     top: 260px;
   }

   @media screen and (max-width: 1399px) {
     left: -45px;
     top: 205px;
   }

   @media screen and (max-width: 1199px) {
     left: 40px;
     top: 430px;
   }

   @media screen and (max-width: 999px) {
     left: 40px;
     top: 430px;
   }
 }


  .banner{
    position: absolute;
    background-image: url("../images/title_banner.png");
    background-repeat: no-repeat;
    width: 150px;
    height: 37px;
    background-size: contain;
    justify-content: center;
    display: flex;
    z-index: 12;
  }

  .banner span{
    color: #ffffff;
    font-size: 22px;
    position: absolute;
  }

  .banner-1 {
    opacity: 1;
    left: 422px;
    top: 410px;


    @media screen and (max-width: 1919px) {
      left: 392px;
      top: 387px;
    }

    @media screen and (max-width: 1799px) {
      left: 335px;
      top: 315px;
    }

    @media screen and (max-width: 1599px) {
      left: 287px;
      top: 275px;
    }

    @media screen and (max-width: 1399px) {
      left: 243px;
      top: 222px;
    }

    @media screen and (max-width: 1199px) {
      left: 267px;
      top: 265px;
    }

    @media screen and (max-width: 999px) {
      left: 267px;
      top: 265px;
    }
  }

  .banner-2 {
    opacity: 1;
    left: 1456px;
    top: 333px;

    @media screen and (max-width: 1919px) {
      left: 1350px;
      top: 310px;
    }

    @media screen and (max-width: 1799px) {
      left: 1200px;
      top: 250px;
    }

    @media screen and (max-width: 1599px) {
      left: 1060px;
      top: 230px;
    }

    @media screen and (max-width: 1399px) {
      left: 885px;
      top: 190px;
    }

    @media screen and (max-width: 1199px) {
      left: 1060px;
      top: 230px;
    }

    @media screen and (max-width: 999px) {
      left: 1060px;
      top: 230px;
    }
  }

  .banner-3 {
    opacity: 1;
    left: 1620px;
    top: 795px;


    @media screen and (max-width: 1919px) {
      left: 1535px;
      top: 725px;
    }

    @media screen and (max-width: 1799px) {
      left: 1360px;
      top: 690px;
    }

    @media screen and (max-width: 1599px) {
      left: 1200px;
      top: 590px;
    }

    @media screen and (max-width: 1399px) {
      left: 985px;
      top: 515px;
    }

    @media screen and (max-width: 1199px) {
      left: 1200px;
      top: 590px;
    }

    @media screen and (max-width: 999px) {
      left: 1200px;
      top: 590px;
    }
  }

 .banner-4 {
   opacity: 1;
   left: 180px;
   top: 740px;


   @media screen and (max-width: 1919px) {
     left: 180px;
     top: 740px;
   }

   @media screen and (max-width: 1799px) {
     left: 123px;
     top: 640px;
   }

   @media screen and (max-width: 1599px) {
     left: 123px;
     top: 550px;
   }

   @media screen and (max-width: 1399px) {
     left: 100px;
     top: 474px;
   }

   @media screen and (max-width: 1199px) {
     left: 123px;
     top: 640px;
   }

   @media screen and (max-width: 999px) {
     left: 123px;
     top: 640px;
   }
 }
